<template>
  <div id="firstPage" class="seleaq">
    <div class="wrap">
      <div class="f-select s01" @click="showselect(1)">
        <div class="s-t">模型名称11</div>
        <div class="fs-text">
          <div class="text-c">
            {{ value01.text }}
          </div>
          <img src="@/assets/img/i13.png" alt="" class="icon-s" />
        </div>
      </div>
      <div class="obv-wrap">
         <img
          v-if="imgdata&&imgdata.FileType == 2"
          class="imgdata"
          :src="baseUrl + imgdata.ImgUrl"
          alt=""
        />
        <obvCompIndex
          ref="obvCompIndex"
          @selection="selection"
       
          @loaded="loaded"
        ></obvCompIndex>
      </div>
      <div class="f-select s02" @click="showselect(2)">
        <div class="s-t">选择模型列表</div>
        <div class="fs-text">
          <div class="text-c">
            {{ value02.text }}
          </div>
          <img src="@/assets/img/i13.png" alt="" class="icon-s" />
        </div>
      </div>
      <div class="component-selected" v-if="selectedcomponentdata">
        <div class="icon-f">!</div>
        <div class="tips">
          您已选中：<span v-if="selectedfloor">{{ selectedfloor }}-</span>
          <span>{{ selectedcomponentdata.Name }}</span>
        </div>
      </div>
      <div
        class="btn-s b01"
        :class="{ disable: !selectedcomponentdata||value02.text=='请选择楼层' }"
        @click="next"
      >
        确认
      </div>
    </div>
    <van-picker
      v-if="pshow && columns && columns.length"
      title="标题"
      show-toolbar
      :columns="columns"
      :default-index="defaultindex"
      @confirm="onConfirm"
      @cancel="onCancel"
      @change="onChange"
    />
  </div>
</template> 
<script>
import { GetComponentModelList, GetDataList, GetBIMlList } from "@/js/firstApi";
import { getStorage, setStorage } from "@/js/common";
import obvCompIndex from "../obvs/obvViewIndex";
import { Picker, Toast } from "vant";
import wx from "weixin-js-sdk";
import { baseUrl } from "@/js/util";
export default {
  name: "firstPage",
  data() {
    return {
      urn: null, // 模型的 urn
      baseUrl: baseUrl,
      columns: [],
      columns01: [],
      columns02: [],
      pshow: false,
      value01: { text: "请选择楼号" },
      value02: { text: "请选择楼层" },
      selectactiveindex: "",
      defaultindex: 0,
      mlist: [], // 后台存储的构件数组
      selectedcomponentdata: "", // 被选中的构件数据
      selectedfloor: "", // 被选中的楼层
      navid: "", // 1质量 2安全 3 生产 (一个参数传了2个页面 正式版 用 vuex)
      token: "",
      TypeID: "",
      pid: "",
      imgdata:'',
    };
  },
  components: {
    obvCompIndex,
    [Picker.name]: Picker,
    [Toast.name]: Toast,
  },
  computed: {},
  mounted() {
    // this.getmodelData();

//     var numbers = [15.5, 2.3, 1.1, 4.7];

// function getSum(total, num) {
//     return total + Math.round(num);
// }
// function myFunction(item) {
//     console.log(numbers.reduce(getSum, 0));
// }
    if (this.$route.query) {
      if (this.$route.query.token) {
        this.token = this.$route.query.token;
      }
      if (this.$route.query.TypeID) {
        this.TypeID = this.$route.query.TypeID;
      }
      if (this.$route.query.pid) {
        this.pid = this.$route.query.pid;
      }
    }
  this.GetBIMlList();
  },
  methods: {
    GetBIMlList() {
      // 获取模型列表
      GetBIMlList({ TypeID: this.TypeID, ProjectID: this.pid }, this.token)
        .then((res) => {
          console.log(res);
          let { Code, Data, Message } = { ...res.data };
          if (Code == 1 && Data) {
            Data.forEach((v) => {
              v.text = v.Name;
            });
            this.columns01 = Data;
            this.value01 = Data[0];
             this.imgdata = Data[0];
          
            if (Data[0].FileType == 1) {
             
              // this.$nextTick(() => {
              //   this.$refs.obvCompIndex.modelInit([Data[0].ImgUrl], "");
              // });
            } else if (Data[0].FileType == 2) {
              
            }
            this.getmodelData();
          } else {
            console.log(Message);
          }
        })
        .catch((error) => {});
    },
    loaded(modelId) {
      // this.GetDataList(modelId);
    },
    GetDataList(modelId) {
      GetDataList({ InspectionType: this.navid })
        .then((res) => {
          console.log(res);
          let { code, data, message } = { ...res.data };
          if (code == 1 && data && data.length) {
            let cl = [];
            data.forEach((v) => {
              cl.push({
                dbId: v.key,
                modelId: modelId,
              });
            });
            obvApiOne.setObjectsColor(cl, 255, 0, 0, 1);
          } else {
            console.log(message);
          }
        })
        .catch((error) => {});
    },
    next() {
      if (this.selectedcomponentdata&&this.value02.text!='请选择楼层') {
        let LocationName = "";
        if (this.selectedfloor) {
          LocationName = `${this.selectedfloor}-${this.selectedcomponentdata.Name}-${this.value02.ComponentId}`;
        } else {
          LocationName = this.selectedcomponentdata.Name;
        }
        this.selectedcomponentdata.LocationName = LocationName;
        this.selectedcomponentdata.mID = this.value01.ID;
        console.log('wx',wx,'this.selectedcomponentdata',this.selectedcomponentdata,18777)
        wx.miniProgram.postMessage({
          data: JSON.stringify(this.selectedcomponentdata),
        });
        wx.miniProgram.navigateBack({ delta: 1 });
      }
    },
    selection(data) {
      // 选中某个构件
      let mlist = this.mlist;
      mlist.forEach((v,index) => {
        if (v.ComponentId == data.dbId) {
          console.log(v,index);
          this.selectedcomponentdata = v;
        }
      });
    },
    getmodelData() {
      // 获取模型相关数据
      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "模型数据获取中……",
      });
      if (this.urn) {
        this.$refs.obvCompIndex.unnitObv();
      }
      this.value02 = { text: "请选择楼层" };
      GetComponentModelList({ BIMID: this.value01.ID }, this.token)
        .then((res) => {
          console.log(res);

          let { Code, Data, Message } = { ...res.data };
          if (Code == 1 && Data) {
            // setStorage('mdata',data);
            let floordata = [];
            let componentModel = Data.componentModel;
            if (componentModel && componentModel.length) {
              componentModel.forEach((res) => {
                res.text = res.Name;
              });
            }
            floordata = componentModel.concat({ text: "显示全部" });
            console.log(floordata)
            this.columns02 = floordata;
            this.mlist = Data.allComponentModel;

            if (Data.urn) {
              this.urn = Data.urn;
              this.$nextTick(() => {
                this.$refs.obvCompIndex.modelInit([Data.urn], "");
              });
            }
          } else {
            console.log(Message);
          }
          Toast.clear();
        })
        .catch((error) => {
          Toast.clear();
        });

      // }
    },
    showselect(selectactiveindex) {
      // 点击弹出 picke 弹框
      this.selectactiveindex = selectactiveindex;
      if (selectactiveindex == 1) {
        this.columns = this.columns01.concat();
      } else if (selectactiveindex == 2) {
        this.columns = this.columns02.concat();
      }
      this.pshow = true;
    },
    onConfirm(value, index) {
      // picke 点击确认
       this.imgdata = value;
       if (this.imgdata.FileType == 1) {
     
          this.$nextTick(() => {
            this.$refs.obvCompIndex.modelInit([this.imgdata.ImgUrl], "");
          });
          // if (this.urn) {
          //   this.$refs.obvCompIndex.unnitObv();
          // }
        } else if (this.imgdata.FileType == 2) {
        }
      this.pshow = false;
      this.defaultindex = index;
      if (this.selectactiveindex == 1) {
        console.log(value, index);
        this.value02 = { text: "请选择楼层" };
        this.value01 = value;
        this.selectedfloor = "";
        this.selectedcomponentdata = "";
        this.getmodelData();
      } else if (this.selectactiveindex == 2) {
        this.value02 = value;
        console.log(this.value02);
        if (this.value02.text == "显示全部") {
          obvApiOne.showAll();
          this.selectedcomponentdata = "";
          this.selectedfloor = "";
        } else {
          obvApiOne.hideAll();
          obvApiOne.show([
            {
              dbId: this.value02.ComponentId,
              modelId: obvApiOne.getModelIds()[0],
            },
          ]);
          this.selectedcomponentdata = "";
          this.selectedfloor = this.value02.text;
        }
      }
    },
    onChange(picker, value, index) {
      // picke 改变（滑动）
      // Toast(`当前值：${value}, 当前索引：${index}`);
    },
    onCancel() {
      // pick 点击取消
      this.pshow = false;
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#firstPage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  overflow-y: auto;
  box-sizing: border-box;
  background: #f7f7f7;
  /deep/ {
    #v3d-viewer {
      display: none;
    }
  }
  .f-select {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 1rem;
    height: 0.4rem;
    padding: 0 0.1rem;
    background: #fff;
    &.s02 {
      border-bottom: 1px solid #f7f7f7;
      .fs-text {
        color: #333333;
      }
    }
    &.s03 {
      border-bottom: 1px solid #f7f7f7;

      .fs-text {
        color: #333333;
      }
    }
    &.s04 {
      border-bottom: none;
      padding: 0;
    }
    .s-t {
      font-size: 0.14rem;
      color: #333333;
    }
    .fs-text {
      font-size: 0.14rem;
      display: flex;
      align-items: center;
      color: #0083ff;
      .status1 {
        color: #2ace5c;
      }
      .status2 {
        color: #8d928e;
      }
      .status3 {
        color: #ce432a;
      }
    }
    .icon-s {
      width: 0.2rem;
      height: 0.2rem;
      object-fit: contain;
      transform: rotate(-90deg);
    }
  }
  .wrap {
    width: 100%;
    height: 100%;
    position: relative;
    box-sizing: border-box;
    padding: 0 0 0.45rem;
    overflow-y: auto;
    .des-c {
      // margin: 8px 0 0;
      padding: 0 0.1rem;
      .van-cell {
        border: 1px solid #ddd;
        border-radius: 3px;
        background: #f3f3f3;
        font-size: 0.14rem;
        color: #333333;
      }
    }
    .obv-wrap {
      width: 100%;
      height: 3rem;
      position: relative;
    }
    .component-selected {
      font-size: 0.12rem;
      line-height: 0.2rem;
      color: red;
      margin: 10px 0 0;
      padding: 0 0.26rem;
      position: relative;
      .icon-f {
        color: #fff;
        background: red;
        width: 0.13rem;
        height: 0.13rem;
        line-height: 0.13rem;
        text-align: center;
        position: absolute;
        left: 0.1rem;
        top: 50%;
        transform: translate(0, -50%);
        border-radius: 50%;
      }
      span {
        padding: 0 10px 0 0;
      }
    }
  }
  .van-picker {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
  }
  .btn-s {
    height: 0.45rem;
    line-height: 0.45rem;
    color: #fff;
    font-size: 0.15rem;
    text-align: center;
    position: absolute;
    bottom: 0;
    background: #0083ff;
    left: 0;
    right: 0;
    &.disable {
      opacity: 0.3;
    }
    &.b02 {
      right: 0;
      background: green;
    }
    &.b03 {
      right: 40%;
      background: red;
    }
  }
}
</style>